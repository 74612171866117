@import '../../../styles/all.scss';

$fb-btn-color: #3b579d;
$google-btn-color: #4285f4;

.c-SocialLogins {
  &__err-msg {
    @include fontRegular(12px, $danger-color);
    margin: 16px 0;
  }

  &__social-btn-wrapper {
    :global {
      .c-Button {
        position: relative;
        @include fontRegularCMC(14px, $dark-color);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DBDBD7;
        border-radius: 4px;
        background-color: $white-text;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

        &:hover {
          background-color: $disabled-bg-color;
        }
      }
    }

    &--google {
      margin-bottom: 16px;

      :global {
        .c-Button {
          &::before {
            position: absolute;
            left: 16px;
            content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/google_logo.svg');
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
        }
      }
    }

    &--facebook {
      :global {
        .c-Button {
          &::before {
            position: absolute;
            left: 16px;
            content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/facebook_logo.svg');
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
        }
      }
    }

    &--apple {
      margin-top: 16px;

      :global {
        .c-Button {
          &::before {
            position: absolute;
            left: 16px;
            content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/apple-24.png');
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
